<template>
  <div class="success h-100 w-100">
    <div class="container h-100">
      <div class="row h-100 py-5 justify-content-center">
        <div class="col-12 d-flex flex-column align-items-center justify-content-center">
          <img src="../assets/check-success.svg"
               class="success__img mb-4"
               alt="">
          <template v-if="$store.state.credentials.uuid">
            <h2 class="success__title">
              Your account was successfully created!
            </h2>
            <p class="success__text">
              Access details were sent to your email
            </p>
            <div class="succes-credentials mb-4">
              <p class="success__cred">
                Email: <b>{{$store.state.credentials.email}}</b>
              </p>
              <p class="success__cred">
                Password: <b>{{$store.state.credentials.password}}</b>
              </p>
            </div>
            <div class="d-flex justify-content-center mb-5">
              <a type="button"
                 :href="`https://app.expertchat.me/?auth=${$store.state.credentials.uuid}`"
                 class="btn btn-primary site-btn site-btn_primary"
              >
                Web Version
              </a>
            </div>
          </template>
          <template v-else>
            <h2 class="success__title">
              The email has already been taken.
            </h2>
          </template>
          <p class="success">
            In case you haven’t found email with instructions, please, check Spam and other email
            folders or contact us via
          </p>
          <a href="mailto:support@expertchat.me">
            support@expertchat.me
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Success',
};
</script>

<style lang="scss" scoped>
.success {

  &__img {
    max-width: 250px;
    width: 100%;
    height: auto;
  }
}
</style>
